<template>
  <div class="ecvOwlType2">
    <v-img
      cover
      :height="imgHeight"
      :src="computedPhotoUrl"
      :alt="photoName"
    ></v-img>
    <div class="ecvOwlBox">
      <v-container
        class="
          d-flex
          flex-column
          align-center
          inner-container inner-container--content
        "
      >
        <div v-if="thumbnailUrl">
          <v-img
            cover
            :width="thumbnailWidth"
            class="mb-2"
            :aspect-ratio="thumbnailRatio"
 
            :src="thumbnailUrl"
            :alt="thumbnailName"
          >
          </v-img>
        </div>
        <div class="d-flex flex-column align-center">
          <h1 class="ecvOwlTitle text-overflow-ep-3 text-overflow-ep-md-2">{{ title }}</h1>
          <p class="ecvOwlContent white--text text-overflow-ep-2 tmw-20-30">
            {{ subtitle }}
          </p>
          <Main-btn
            checkLink
            color="primary"
            class="banner-btn white--text"
            linkOut
            :to="buttonUrl"
            :targetBlank="targetBlank"
          >
            {{ buttonText }}
          </Main-btn>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import bannerItem from "./bannerItem.js";

export default {
  mixins: [bannerItem],
  computed: {
    thumbnailRatio() {
      if (this.$vuetify.breakpoint.mdAndUp) return 350/135;
      return 350/135;
    },
    thumbnailWidth() {
      if(this.windowWidth > this.$vuetify.breakpoint.thresholds.sm) return 350
      return 311
    },
  },
};
</script>