<template>
  <div class="ecvOwlType3">
    <v-img cover :height="imgHeight" :src="computedPhotoUrl" :alt="photoName"></v-img>
    <div class="ecvOwlBox">
      <div>
        <h1 class="ecvOwlTitle text-overflow-ep-3 text-overflow-ep-md-2">{{ title }}</h1>
    
        <div v-html="nl2br(content)" class="ecvOwlContent white--text tmw-20-30 text-overflow-ep-5 mb-4"></div>
        <div class="text-center text-md-left">
          <Main-btn
            checkLink
            color="primary"
            class="banner-btn white--text"
            linkOut
            :to="buttonUrl"
            :targetBlank="targetBlank"
          >
            {{ buttonText }}
          </Main-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bannerItem from "./bannerItem.js";

export default {
  mixins: [bannerItem],
};
</script>
