<template>
  <div :id="anchorName" class="ecvBanner">
    <h2 v-if="showTitle" class="dynamic-main-title tmw-40-32-15">
      {{ title }}
    </h2>
    <div class="ecvBanner__content">
      <component :is="topWaveComponent" class="top-wave"></component>
      <Owl :options="computedOptions">
        <template v-slot:items>
          <div v-for="(item, i) in items" :key="i">
            <component :item="item" :is="bannerItem(item)" />
          </div>
        </template>
      </Owl>
      <component
        :is="bottomWaveComponent"
        class="bottom-wave"
        :class="bottomWaveClass"
      ></component>
    </div>
  </div>
</template>

<script>
import owl from "../owl.js";
import dynamicBlock from "@/components/dynamic/dynamicBlock.js";
import EcvWaveT from "@/components/wave/ecvWaveT.vue";
import EcvWaveBannerT from "@/components/wave/ecvWaveBannerT.vue";
import bannerItem1 from "./item/bannerItem1.vue";
import bannerItem2 from "./item/bannerItem2.vue";
import bannerItem3 from "./item/bannerItem3.vue";
export default {
  mixins: [owl, dynamicBlock],
  props: {
    index: Number,
  },
  computed: {
    defaultOptions() {
      return {
        items: 1,
        nav: false,
        loop: false,
        autoplayHoverPause: true,
        mouseDrag: this.items.length == 1 ? false : true,
        touchDrag: this.items.length == 1 ? false : true,
        autoplay: false,
        autoplayTimeout: this.timeout,
      };
    },
    computedOptions() {
      return {
        ...this.defaultOptions,
        ...this.options,
      };
    },
    topWaveComponent() {
      return EcvWaveBannerT;
    },
    autoplay() {
      if (!this.blockData) return false;
      if(this.items.length == 1) return false
      return this.blockData.autoplay;
    },
    timeout() {
      if (!this.blockData) return 6000;
      if (!this.blockData.interval) return 6000;
      return this.blockData.interval * 1000;
    },
    isTop() {
      return this.index == 0;
    },
    showTitle() {
      if (!["page", "page-preview"].includes(this.$route.name)) return false;
      return !this.isTop;
    },
    title() {
      if (!this.data) return "";
      return this.data.name;
    },
  },
  methods: {
    bannerItem(item) {
      if (item.style == 1) return bannerItem1;
      if (item.style == 2) return bannerItem2;
      if (item.style == 3) return bannerItem3;
      return null;
    },
  },
};
</script>