import owlItem from "@/components/owl/owlItem.js";
export default {
  mixins: [owlItem],
  computed: {
    buttonText() {
      if (!this.item) return "";
      return this.item.buttonText;
    },
    buttonUrl() {
      if (!this.item) return "";
      return this.$helper.modifyUrl(this.item.buttonUrl, this)
    },
    targetBlank() {
      if (!this.item) return false;
      return this.item.targetBlank;
    },
    backgroundColorLeftBottom() {
      if (!this.item) return "";
      return this.item.backgroundColorLeftBottom;
    },
    backgroundColorRightTop() {
      if (!this.item) return "";
      return this.item.backgroundColorRightTop;
    },
    windowWidth() {
      return this.$vuetify.breakpoint.width;
    },
    imgHeight() {
      if(this.windowWidth > this.$vuetify.breakpoint.thresholds.xs) return 550
      return 500
    },
    computedPhotoUrl() {
      if (this.windowWidth >= 768) return this.photoUrl;
      return this.mobilePhotoUrl;
    },
    mobilePhotoUrl() {
      if (!this.item) return "";
      return this.$helper.getPhotoUrl(this.item.mobilePhoto);
    },
    thumbnailUrl() {
      if (!this.item) return "";
      return this.$helper.getPhotoUrl(this.item.thumbnail);
    },
    thumbnailName() {
      if (!this.item) return "";
      return this.$helper.getPhotoName(this.item.thumbnail);
    },
    bgGradient() {
      return `linear-gradient(45deg, ${this.backgroundColorLeftBottom}, ${this.backgroundColorRightTop})`;
    },
  },
};
